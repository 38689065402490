import React, {useEffect, useState} from "react";
import {Container, Row, Col, Form, Button, Image} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import instructions from "./instruction.json";

const keys = Object.keys(instructions);

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
    };
}

const Modal = ({ isOpen, close }) => {
    if (!isOpen) {
        return null;
    }

    const stopClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div
            onClick={close}
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'grid',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.3)'
            }}
        >
            <div
                onClick={stopClick}
                style={{
                    position: 'relative',
                    background: 'white',
                    padding: '50px',
                    width: '80%',
                    maxWidth: '800px',
                    borderRadius: '5px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                }}
            >
                <button
                    onClick={close}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        background: 'transparent',
                        border: 'none',
                        fontSize: '1.5em'
                    }}
                >
                    X
                </button>
                <h2>Generate a ChatGPT Prompt with Generate-Prompt.com</h2>
                <p>
                    Welcome to Generate-Prompt.com, the ultimate resource to help users create engaging and effective prompts for ChatGPT. Our website offers a wide range of features and tools that make crafting the perfect prompt a breeze.
                </p>
                <p>
                    With Generate-Prompt.com, you can access a vast library of pre-made prompts, customize them according to your needs, and generate new prompts with our state-of-the-art AI technology. Our user-friendly interface makes it easy for everyone to get the most out of ChatGPT.
                </p>
                <h3>Key Features</h3>
                <ul>
                    <li>Extensive library of pre-made prompts</li>
                    <li>Customization options for tailored prompts</li>
                    <li>AI-driven prompt generation technology</li>
                    <li>User-friendly interface and design</li>
                    <li>Comprehensive support and resources</li>
                </ul>
                <p>
                    Don't let the challenge of creating a ChatGPT prompt hold you back. Use Generate-Prompt.com to unlock the full potential of ChatGPT and elevate your conversational AI experience.
                </p>
            </div>
        </div>
    );

}

const App = () => {
    const styles = {
        inputText: {
            marginBottom: "10%",
        },
        checkboxContainer: {
            paddingRight: "1rem",
            paddingLeft: "1rem",
            paddingBottom: "1rem",
        },
        checkBox: {
            marginBottom: getWindowDimensions().height * 0.01,
        },
        dropdown: {
            marginBottom: "5%",
        },
        col: {
            height: getWindowDimensions().height / 2.5,
        },
        row: {
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#dee2e6",
            marginLeft: "5%",
            marginRight: "5%",
            paddingTop: "3.5%",
        },
        container: {
            padding: 0,
            margin: 0,
            maxWidth: "100%",
        },
    };
    const [checked, setChecked] = useState([]);
    const [text, setText] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        keys.map(() => {
            setChecked((prevState) => [...prevState, true]);
            setText((prevState) => [...prevState, ""]);
        });
    }, []);

    useEffect(() => {
        checked.map((item, index) => {
            if (document.getElementById(`chk-${index}`)) {
                document.getElementById(`chk-${index}`).checked = true;
                handleCheckboxChange(index);
            }
        });
    }, [checked]);

    const copyButton = () => {
        var copyText = "";
        checked.map((item, index) => {
            var txt = text[index];
            if (item == false) {
                copyText = copyText + txt + "\n \n";
            }
        });
        navigator.clipboard.writeText(copyText);
    };

    const handleCheckboxChange = (index) => {
        var checkValue = checked[index];
        if (checkValue) {
            document.getElementById("txt-" + index).removeAttribute("disabled");
            document.getElementById("drp-" + index).removeAttribute("disabled");

            let selectedValue = document.getElementById("drp-" + index).options[0]
                .value;
            document.getElementById("txt-" + index).value = selectedValue;
            text[index] = selectedValue;
            setText(text);
        } else {
            document.getElementById("txt-" + index).value = "";
            document.getElementById("txt-" + index).setAttribute("disabled", "true");
            document.getElementById("drp-" + index).setAttribute("disabled", "true");
            document.getElementById("drp-" + index).selectedIndex = 0;

            setText(text);
        }
        checked[index] = !checkValue;
        setChecked(checked);
    };
    const handleDropdown = (e) => {
        let array = e.target.id.toString().split("-");
        let selectedValue = e.target.options[e.target.selectedIndex].value;
        document.getElementById("txt-" + array[1]).value = selectedValue;
        text[array[1]] = selectedValue;
        setText(text);
    };

    return (
            <Container style={styles.container}>
                <Navbar bg="light" variant="dark">
                    <Container>
                        <Nav className="me-auto">
                            <Image src={require("./images/logo.png")} width={"200px"}/>
                        </Nav>
                    </Container>
                </Navbar>

                <Col style={styles.row}>
                    <div className="p-3" style={{alignItems: "flex-start"}}>
                        {keys.map((key, index) => {
                            const options = Object.keys(instructions[key].options);
                            return (
                                <>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <div style={styles.checkboxContainer}>
                                                <Form.Check
                                                    onChange={() => {
                                                        handleCheckboxChange(index);
                                                    }}
                                                    style={styles.checkBox}
                                                    type={"checkbox"}
                                                    label={`${instructions[key].title.fr}`}
                                                    id={`chk-${index}`}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Select
                                                style={styles.dropdown}
                                                disabled={checked[index]}
                                                id={`drp-${index}`}
                                                aria-label={instructions[key].title.fr}
                                                onChange={handleDropdown}
                                            >
                                                {options.map((option, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={instructions[key].options[option].value.fr}
                                                        >
                                                            {instructions[key].options[option].title.fr}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Control
                                                style={styles.inputText}
                                                as="textarea"
                                                rows="3"
                                                label={`${key}`}
                                                id={`txt-${index}`}
                                                disabled={checked[index]}
                                                onChange={(e) => {
                                                    text[index] = e.target.value;
                                                    setText(text);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            );
                        })}
                        <Row className="mt-2 justify-content-end">
                            <Col md={4} className="mt-2 justify-content-end">
                                <Button
                                    className="mt-2 justify-content-end"
                                    variant="secondary"
                                    type="button"
                                    onClick={copyButton}
                                >
                                    Copy to clipboard
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <div
                    className="text-center p-3"
                    style={{
                        backgroundColor: "#f8f9fa",
                        marginTop: "2rem"
                    }}
                >
                    Copyright © 2023&ensp;-&ensp;
                    <span onClick={toggleModal}>
                        About Generate-Prompt.com
                    </span>
                    <Modal isOpen={isOpen} close={toggleModal} />
                </div>
            </Container>
    );
};

export default App;
